import * as React from "react";
import BadgeIcon from '@mui/icons-material/Badge';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EditPages from "./editPagesEnums";

export default function useSettingsPages() {
    const editPages = [
        {
            title: 'Редактирай име и фамилия',
            label: EditPages.NAME,
            icon: <BadgeIcon sx={{ color: 'primary.main' }} />,
        },
        {
            title: 'Редактирай имейл',
            label: EditPages.EMAIL,
            icon: <ContactMailIcon sx={{ color: 'primary.main' }} />,
        },
        {
            title: 'Редактирай потребителско име',
            label: EditPages.USERNAME,
            icon: <AccountCircleIcon sx={{ color: 'primary.main' }} />,
        },
        {
            title: 'Промени парола',
            label: EditPages.PASSWORD,
            icon: <PasswordIcon sx={{ color: 'primary.main' }} />,
        },
    ];

    const dangerZonePages = [
        {
            title: 'Деактивация на акаунт',
            label: EditPages.DEACTIVATE,
            icon: <PersonRemoveIcon sx={{ color: 'error.main' }} />,
        },
    ];

    return {
        editPages, dangerZonePages
    }
};