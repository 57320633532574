import * as React from "react";
import { TextField, Stack } from "@mui/material";
import { textFieldStyle } from "./style";

export default function EditEmail() {
    return (
        <Stack sx={{ ...textFieldStyle }}>
            <TextField variant="filled" label='Имейл' type='email' fullWidth />
        </Stack>
    )
};