import * as React from "react";
import { Stack, TextField } from "@mui/material";
import { textFieldStyle } from "./style";

export default function EditUsername() {
    return (
        <Stack sx={{ ...textFieldStyle }}>
            <TextField variant="filled" label='Потребителско име' type='text' fullWidth />
        </Stack>
    )
};