const paperStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    backgroundColor: 'white',
    padding: 2,
    margin: 1,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f6f8fa',
    }
};

export {
    paperStyle,
}