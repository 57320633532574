import * as React from "react";
import MainContentStructure from "../components/NavigationBar/MainContentStructure";
import { Box, Typography } from "@mui/material";
import SettingsButton from "../components/SettingsPage/SettingsButton";
import useSettingsPages from "../components/SettingsPage/useSettingsPages";
import { mainBox, typography } from "../components/SettingsPage/styles";

export default function Settings() {
    const { editPages, dangerZonePages } = useSettingsPages();

    return (
        <MainContentStructure>
            <Box sx={{ ...mainBox }}>
                <Typography variant="h6" sx={{ ...typography }}>Настройки на профила</Typography>
                {
                    editPages.map(page => <Box key={page.title}>
                        <SettingsButton page={page} />
                    </Box>)
                }

                <Typography variant="h6" sx={{ ...typography }}>Други</Typography>
                {
                    dangerZonePages.map(page => <Box key={page.title}>
                        <SettingsButton page={page} />
                    </Box>)
                }
            </Box>
        </MainContentStructure >
    )
};