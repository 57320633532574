import * as React from "react";
import { TextField, Stack, Typography } from "@mui/material";
import { textFieldStyle } from "./style";

export default function DeactivateProfile() {
    return (
        <Stack sx={{ ...textFieldStyle }}>
            <Typography>
                Моля въведете потребителското си име и парола, за да деактивирате вашия акаунт.
            </Typography>
            <TextField variant="filled" label='Потребителско име' type='email' fullWidth />
            <TextField variant="filled" label='Парола' type='password' fullWidth />
        </Stack>
    )
}