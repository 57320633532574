const mainBox = {
    marginBottom: '64px',
    padding: 2
};

const typography = {
    padding: 2,
}

export {
    mainBox,
    typography
}