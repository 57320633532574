import * as React from "react";
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useScreenResolution from "../hooks/useScreenResolution";
import { dialogTitleStyle } from "./dialogTemplateStyles";
import EditPages from "./editPagesEnums";
import EditName from "./EditPages/EditName";
import EditEmail from "./EditPages/EditEmail";
import EditUsername from "./EditPages/EditUsername";
import EditPassword from "./EditPages/EditPassword";
import DeactivateProfile from "./EditPages/DeactivateProfile";

export default function SettingsDialogTemplate(props) {
    const { openDialog, handleDialog, page } = props;
    const isMobile = useScreenResolution('sm');

    const getDialogContent = () => {
        switch (page.label) {
            case EditPages.NAME:
                return <EditName />;

            case EditPages.EMAIL:
                return <EditEmail />;

            case EditPages.USERNAME:
                return <EditUsername />

            case EditPages.PASSWORD:
                return <EditPassword />

            case EditPages.DEACTIVATE:
                return <DeactivateProfile />;

            default:
                break;
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleDialog}
            fullScreen={isMobile ? true : false}
            fullWidth
        >
            <DialogTitle sx={{ ...dialogTitleStyle }}>
                {page.title}
                <IconButton onClick={handleDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {
                    getDialogContent()
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleDialog}>Откажи</Button>
                <Button variant="contained">
                    {
                        page.label === EditPages.DEACTIVATE
                            ? 'Деактивирай'
                            : 'Редактирай'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
};