import { Paper, Typography } from "@mui/material";
import * as React from "react";
import { paperStyle } from "./settingsButtonStyle";
import SettingsDialogTemplate from "./SettingsDialogTemplate";

export default function SettingsButton({ page }) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleDialog = () => { setOpenDialog(!openDialog) };

    return (
        <>
            <Paper
                onClick={handleDialog}
                sx={{ ...paperStyle }}
            >
                {page.icon}
                <Typography>{page.title}</Typography>
            </Paper>

            <SettingsDialogTemplate
                openDialog={openDialog}
                handleDialog={handleDialog}
                page={page}
            />
        </>
    )
};