import * as React from "react";
import { TextField, Stack } from "@mui/material";
import { textFieldStyle } from "./style";

export default function EditPassword() {
    return (
        <Stack sx={{ ...textFieldStyle }}>
            <TextField variant="filled" label='Сегашна парола' type='password' fullWidth />
            <TextField variant="filled" label='Нова парола' type='password' fullWidth />
            <TextField variant="filled" label='Потвърди нова парола' type='password' fullWidth />
        </Stack>
    )
};